import React, { Component } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "../styles/global-styles"
import ContentDate from "./ContentDate"
import ReadOnButton from "./ReadOnButton"
import get from "lodash/get"
import { useStaticQuery, graphql } from "gatsby"

const MusingCardWrapper = styled.section`
  max-width: 960px;
  width: 100%;
  margin: 5rem auto 2rem auto;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 4rem 0 4rem;
    margin: 0;
  }
  @media only screen and (max-width: 599px) {
    padding: 1.5em 1.5rem 0 1.5rem;
  }
`

const MusingCardTextContainer = styled.div`
  max-width: 45%;
  @media only screen and (max-width: 990px) {
    max-width: 100%;
    margin-bottom: 6rem;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const MusingCardH4 = styled.h4`
  margin: 0%;
`

const MusingCard = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulBlogPost {
        edges {
          node {
            id
            title
            publishDate(formatString: "MMMM Do, YYYY")
            description
            slug
          }
        }
      }
    }
  `)

  let blogArray = data.allContentfulBlogPost.edges
  console.log("I am the blogArray: ", blogArray)

  return (
    <MusingCardWrapper>
      <MusingCardTextContainer key={blogArray[0].node.id}>
        <MusingCardH4>{blogArray[0].node.title}</MusingCardH4>
        <ContentDate
          color={theme.colors.accentOne}
          date={blogArray[0].node.publishDate}
        />
        <p
          css={css`
            margin-bottom: 2rem;
          `}
        >
          {blogArray[0].node.description}
        </p>

        <ReadOnButton
          btnLink={`/musings/${blogArray[0].node.slug}`}
          btnColor={theme.colors.accentTwo}
          btnTextColor={theme.colors.light}
          btnHoverColor={theme.colors.accentOne}
          btnWidth={"200px"}
          btnHeight={"40px"}
          btnText={"Read On"}
        />
      </MusingCardTextContainer>
      <MusingCardTextContainer key={blogArray[1].node.id}>
        <MusingCardH4>{blogArray[1].node.title}</MusingCardH4>
        <ContentDate
          color={theme.colors.accentOne}
          date={blogArray[1].node.publishDate}
        />
        <p
          css={css`
            margin-bottom: 2rem;
          `}
        >
          {blogArray[1].node.description}
        </p>
        <ReadOnButton
          btnLink={`/musings/${blogArray[1].node.slug}`}
          btnColor={theme.colors.accentTwo}
          btnTextColor={theme.colors.light}
          btnHoverColor={theme.colors.accentOne}
          btnWidth={"200px"}
          btnHeight={"40px"}
          btnText={"Read On"}
        />
      </MusingCardTextContainer>
    </MusingCardWrapper>
  )
}

export default MusingCard
