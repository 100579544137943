import React, { useRef } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import MainNav from "../components/MainNav"
import ExperienceContainer from "../components/ExperienceContainer"
import LargeSectionHeader from "../components/LargeSectionHeader"
import SmallSectionHeader from "../components/SmallSectionHeader"
import MusingCard from "../components/MusingCard"
import { css } from "@emotion/core"
import Footer from "../components/Footer"
import { theme } from "../styles/global-styles"
import ReadOnButton from "../components/ReadOnButton"
import jmResume from "../../public/Joshua_Maddox_Resume.pdf"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Joshua Maddox: Father, Coder, Marketer, and Designer" />
      <MainNav
        logoAndMenuColor={theme.colors.dark}
        bgColor={theme.colors.white}
        hamburgerOn={false}
        stickyOn={true}
      />
      <Hero />

      <LargeSectionHeader
        headerTitle={"Resume"}
        color={theme.colors.accentTwo}
        idForReactScroll={"resume"}
        pointsForSVG={
          "0 0, 140 4, 220 0, 367 10, 429 0, 502 5, 680 20, 669 60, 680 90, 616 80, 540 87, 439 90, 367 83, 221 88, 139 90, 45 85, 0 89"
        }
        viewBoxForSVG={"0 0 680 90"}
      />

      <ExperienceContainer id="resume" />
      <div
        css={css`
          width: 100%;
          text-align: center;
          margin-top: 9rem;
          margin-bottom: 5rem;
        `}
      >
        <ReadOnButton
          btnLink={jmResume}
          btnColor={theme.colors.accentTwo}
          btnWidth={"10rem"}
          btnHeight={"2rem"}
          btnText={"Download Full Resume"}
          btnTextColor={"#fff"}
          btnHoverColor={theme.colors.accentOne}
        />
      </div>
      <LargeSectionHeader
        headerTitle={"Musings"}
        idForReactScroll={"musings"}
        color={theme.colors.main}
        pointsForSVG={
          "0 0, 140 4, 220 0, 367 10, 429 0, 502 5, 680 20, 669 60, 680 90, 616 80, 540 87, 439 90, 367 83, 221 88, 139 90, 45 85, 0 89"
        }
        viewBoxForSVG={"0 0 680 90"}
      />
      <MusingCard id="musings" />
      {/* <LargeSectionHeader
        headerTitle={"Projects"}
        color={theme.colors.accentOne}
        pointsForSVG={
          "0 0, 140 4, 220 0, 367 10, 429 0, 502 5, 680 20, 669 60, 680 90, 616 80, 540 87, 439 90, 367 83, 221 88, 139 90, 45 85, 0 89"
        }
        viewBoxForSVG={"0 0 680 90"}
      /> */}
      <Footer />
    </Layout>
  )
}

export default IndexPage
