import React from "react"
import { theme } from "../styles/global-styles"
import styled from "@emotion/styled"

const Button = styled.a`
  width: ${props => props.btnWidth};
  height: ${props => props.btnHeight};
  background-color: ${props => props.btnColor};
  color: ${props => props.btnTextColor};
  text-decoration: none;
  margin-top: 2rem;
  padding: 0.7rem 2rem 0.7rem 2rem;
  :hover {
    background-color: ${props => props.btnHoverColor};
    transition: 0.5s;
    color: ${props => props.btnTextColor};
  }
`

const ReadOnButton = ({
  btnLink,
  btnColor,
  btnWidth,
  btnHeight,
  btnText,
  btnTextColor,
  btnHoverColor,
}) => {
  return (
    <Button
      href={btnLink}
      btnColor={btnColor}
      btnTextColor={btnTextColor}
      btnWidth={btnWidth}
      btnHeight={btnHeight}
      btnHoverColor={btnHoverColor}
    >
      {btnText}
    </Button>
  )
}

export default ReadOnButton
