import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "../styles/global-styles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SmallSectionHeader from "./SmallSectionHeader"

const SectionWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`
const SectionContainer = styled.div`
  width: 100%;
  margin: 0em 4rem 0 4rem;
  max-width: ${theme.widths.mainContainer};
  @media only screen and (max-width: 959px) {
    max-width: ${theme.widths.smContainer};
  }
  @media only screen and (max-width: 770px) {
    margin: 4em 0 0 0;
    :first-of-type {
      margin: 0em 0 0 0;
    }
  }
  @media only screen and (max-width: 599px) {
    margin: 2em 0 0 0;
  }
`

const ExperienceWrapper = styled.article`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;

  :last-of-type {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 960px) {
    margin-bottom: 5rem;
  }

  @media only screen and (max-width: 770px) {
    margin-bottom: 2rem;
  }
`

const ExperienceCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 740px) {
    flex-direction: column-reverse;
    max-width: 500px;
  }
`

const ExperienceTextContainer = styled.div`
  padding-left: 4rem;
  width: 100%;
  @media only screen and (max-width: 740px) {
    padding: 0 2rem 4rem 2rem;
  }
`

const ExperienceHeader = styled.h4`
  margin-bottom: 0;
`
const ExperienceSubheader = styled.h6`
  margin-top: 0;
  margin-bottom: 2rem;
  color: ${theme.colors.accentOne};
`
const ExperienceBlurb = styled.p``

const ExperienceImageContainer = styled.div`
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 964px) {
    min-width: 300px;
  }

  @media only screen and (max-width: 740px) {
    min-width: 500px;
  }

  @media only screen and (max-width: 740px) {
    min-width: 100%;
  }
`

const ExperienceContainer = () => {
  const data = useStaticQuery(graphql`
    query {
      acumen: file(relativePath: { eq: "AcumenJoshuaMaddoxTest.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cinder: file(relativePath: { eq: "Cinderhouse_Experience.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      british: file(relativePath: { eq: "British_Foundation.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionWrapper>
      <SectionContainer>
        <ExperienceWrapper>
          <ExperienceCards>
            <ExperienceImageContainer>
              <Img fluid={data.acumen.childImageSharp.fluid} />
            </ExperienceImageContainer>
            <ExperienceTextContainer>
              <ExperienceHeader>Acumen.org</ExperienceHeader>
              <ExperienceSubheader>
                Associate Director of Marketing and Technology
              </ExperienceSubheader>
              <ExperienceBlurb>
                Led the marketing and tech team to half a million users after
                surpassing first year marketing goals. Architected, designed,
                and helped develop the totality of Acumen Academy's tech stack
                using Firebase, Google Cloud, React, Gatsby, Emotion, Jest,
                Node.js, GraphQL, Docker etc...
              </ExperienceBlurb>
            </ExperienceTextContainer>
          </ExperienceCards>
        </ExperienceWrapper>
        <ExperienceWrapper>
          <ExperienceCards
            css={css`
              flex-direction: row-reverse;
              @media only screen and (max-width: 775px) {
              }
            `}
          >
            <ExperienceImageContainer>
              <Img fluid={data.cinder.childImageSharp.fluid} />
            </ExperienceImageContainer>
            <ExperienceTextContainer
              css={css`
                flex-direction: row-reverse;
                padding-left: 0;
                padding-right: 4rem;
                @media only screen and (max-width: 775px) {
                }
              `}
            >
              <ExperienceHeader>Cinderhouse Creative</ExperienceHeader>
              <ExperienceSubheader>
                Senior Developer / Co-Founder
              </ExperienceSubheader>
              <ExperienceBlurb>
                Led the marketing, engineering, and design team. Produced
                projects for fortune 500s and SMEs in China, Australia, England,
                and the US. Designed and developed nationwide campaigns using
                technologies such as vanilla JavasScript, React, Google
                Analytics, Salesforce, SASS, CSS, Semantic HTML, MongoDB, SQL,
                Express, Heroku, Adobe Creative Cloud etc...
              </ExperienceBlurb>
            </ExperienceTextContainer>
          </ExperienceCards>
        </ExperienceWrapper>
        <ExperienceWrapper>
          <ExperienceCards>
            <ExperienceImageContainer>
              <Img fluid={data.british.childImageSharp.fluid} />
            </ExperienceImageContainer>
            <ExperienceTextContainer>
              <ExperienceHeader>
                The British Schools Foundation
              </ExperienceHeader>
              <ExperienceSubheader>Head of Administration</ExperienceSubheader>
              <ExperienceBlurb>
                Led a staff of 19, marketing to over 350% growth in 5 years, and
                all non-academic management including campus construction,
                oversight, and contract negotiations for one of the most
                esteemed International schools in Nanjing, China.
              </ExperienceBlurb>
            </ExperienceTextContainer>
          </ExperienceCards>
        </ExperienceWrapper>
      </SectionContainer>
    </SectionWrapper>
  )
}

export default ExperienceContainer
