import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { theme } from "../styles/global-styles"
import HeroPhoto from "./HeroPhoto"

const HeroWrapper = styled.section`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ElementWrapper = styled.div`
  max-width: ${theme.widths.mainContainer};
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 6em 4rem 0 4rem;
  @media only screen and (max-width: 699px) {
    flex-direction: column;
    align-items: center;
    margin: 4rem 0 0 0;
  }

  @media only screen and (max-width: 960px) {
    max-width: ${theme.widths.smContainer};
  }
`

const MainHeader = styled.h1`
  font-size: 6rem;
  color: ${theme.colors.dark};
  line-height: 0;
  margin-bottom: 0.7em;
  height: 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 959px) {
    font-size: 4rem;
  }

  @media only screen and (max-width: 699px) {
    font-size: 3rem;
    &:last-of-type {
      margin-bottom: 4rem;
    }
  }
`
const ImageWrapper = styled.div`
  width: 400px;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: 959px) {
    width: 300px;
  }

  @media only screen and (max-width: 699px) {
    width: 100%;
    margin: 0;
  }
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1.8rem;
`
const Hero = props => {
  return (
    <>
      <HeroWrapper>
        <ElementWrapper>
          <HeaderWrapper>
            <MainHeader>
              FATHER
              <span
                css={css`
                  color: ${theme.colors.mediumHover};
                `}
              >
                !
              </span>
            </MainHeader>
            <MainHeader>
              CODER
              <span
                css={css`
                  color: ${theme.colors.accentOne};
                `}
              >
                .
              </span>
            </MainHeader>
            <MainHeader>
              DESIGNER
              <span
                css={css`
                  color: ${theme.colors.main};
                `}
              >
                .
              </span>
            </MainHeader>
            <MainHeader>
              MARKETER
              <span
                css={css`
                  color: ${theme.colors.accentTwo};
                `}
              >
                .
              </span>
            </MainHeader>
          </HeaderWrapper>
          <ImageWrapper>
            <HeroPhoto
              css={css`
                border: 2px solid orange;
              `}
            />
          </ImageWrapper>
        </ElementWrapper>
      </HeroWrapper>
    </>
  )
}

export default Hero
