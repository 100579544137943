import React from "react"
import styled from "@emotion/styled"
import { theme } from "../styles/global-styles"

const LargeHeaderSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8rem;
  /* margin-top: 5rem; */
  @media only screen and (max-width: 770px) {
    /* margin-top: 3rem; */
    margin-bottom: 5rem;
  }
  @media only screen and (max-width: 500px) {
    /* margin-top: 3rem; */
    margin-bottom: 3rem;
  }
`

const SVGWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
`
const SectionText = styled.h2`
  font-size: 10rem;
  z-index: 10;
  @media only screen and (max-width: 1350px) {
    font-size: 8rem;
  }
  @media only screen and (max-width: 1119px) {
    font-size: 6rem;
  }
  @media only screen and (max-width: 959px) {
    font-size: 6rem;
  }
  @media only screen and (max-width: 649px) {
    font-size: 4rem;
  }
`
const SectionSVG = styled.svg`
  width: 50%;
  height: 50%;
  position: absolute;
  z-index: 1;
  left: 25%;
  top: 55%;
  @media only screen and (max-width: 809px) {
    width: 80%;
    height: 80%;
    left: 12%;
  }
`

const LargeSectionHeader = ({
  headerTitle,
  color,
  pointsForSVG,
  viewBoxForSVG,
  idForReactScroll,
}) => {
  return (
    <LargeHeaderSectionWrapper id={idForReactScroll}>
      <SVGWrapper>
        <SectionText>{headerTitle}</SectionText>
        <SectionSVG fill={color} viewBox={viewBoxForSVG}>
          <polygon points={pointsForSVG} />
        </SectionSVG>
      </SVGWrapper>
    </LargeHeaderSectionWrapper>
  )
}

export default LargeSectionHeader
